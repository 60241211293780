import domready from 'domready';

domready(function () {
  var btn = document.querySelector("#menubutton");
  var menu = document.querySelector("#menu-main");
  btn.addEventListener("click", function() {
    var cl = menu.classList;
    var name = "expanded"
    if(cl.contains(name)) {
      cl.remove(name);
    } else {
      cl.add(name);
    }
  });  
});
