import domready from 'domready';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const placeholder = {
    toString: () => "",
}

L.Icon.Default.imagePath = placeholder;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


domready(function() {
    var map = L.map("map").setView([53.203145, 14.305646], 14);
    var osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    var osmAttrib='Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';
    var osm = new L.TileLayer(osmUrl, {minZoom: 8, maxZoom: 18, attribution: osmAttrib});
    map.addLayer(osm);

    var marker = L.marker([53.203145, 14.305646]);
    marker.addTo(map);
    marker.bindPopup("<h3>Uckermärker SonnenObsthof</h3><p>Gartzer Str. 23<br />16306 Hohenselchow, OT Heinrichshof</p>");
});
